import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface StripeCheckoutButtonProps {
  tier: 'paid' | 'premium';
  disabled?: boolean;
}

const StripeCheckoutButton: React.FC<StripeCheckoutButtonProps> = ({ tier, disabled = false }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async () => {
    try {
      console.log('Checkout initiated for tier:', tier);
      
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/stripe/checkout`, 
        { subscriptionTier: tier },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      );
  
      console.log('Checkout Session Response:', response.data);
      
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');
  
      const { sessionId } = response.data;
      console.log('Redirecting to Stripe Checkout with Session ID:', sessionId);
  
      // Detailed error handling for Stripe redirect
      const { error } = await stripe.redirectToCheckout({ 
        sessionId
      });
  
      if (error) {
        console.error('Stripe Redirect Error Details:', {
          code: error.code,
          message: error.message,
          type: error.type
        });
        throw error;
      }
  
    } catch (err: any) {
      console.error('Full Checkout Error:', {
        message: err.message,
        response: err.response?.data,
        stack: err.stack
      });
      setError(err.message || 'Checkout failed');
    }
  };

  return (
    <div>
      <button
        onClick={handleCheckout}
        disabled={loading || disabled}
        className={`w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300 ${
          (loading || disabled) && 'opacity-50 cursor-not-allowed'
        }`}
      >
        {loading ? 'Processing...' : `Upgrade to ${tier === 'paid' ? 'Paid' : 'Premium'}`}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default StripeCheckoutButton;
