import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import SubscriptionCard from '../components/SubscriptionCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const DashboardPage: React.FC = () => {
  const { accessToken, tokens_remaining, logout, updateTokensRemaining, currentTier, setCurrentTier } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!accessToken) {
          throw new Error('No access token');
        }

        const response = await axios.get(process.env.REACT_APP_ENVIRONMENT_DOMAIN + '/user/me', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        const data = response.data;
        if (data.subscription_tier) {
          setCurrentTier(data.subscription_tier);
        } else {
          setCurrentTier('free');
        }

        if (data.tokens_remaining !== undefined) {
          updateTokensRemaining(data.tokens_remaining);
        }
      } catch (error: any) {
        console.error('Error fetching user data:', error);
        setError(error.message || 'An error occurred');
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [accessToken, logout, navigate, updateTokensRemaining, setCurrentTier]);

  const handleCancelSubscription = async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_ENVIRONMENT_DOMAIN + '/stripe/cancel', {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      alert(response.data.message);
      // After cancellation, the subscription will revert to free at period end
    } catch (error: any) {
      console.error('Error cancelling subscription:', error);
      alert(error.response.data.message || 'Failed to cancel subscription.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-light dark:bg-gradient-dark">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-light dark:border-primary-dark"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto py-8 bg-background-light dark:bg-background-dark text-gray-800 dark:text-gray-200">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  const isPaidDisabled = currentTier === 'premium';
  const isPremiumDisabled = currentTier === 'premium';

  return (
    <div className="container mx-auto py-8 bg-background-light dark:bg-background-dark text-gray-800 dark:text-gray-200">
      <h1 className="text-3xl font-bold mb-8 text-secondary-light dark:text-secondary-dark">Dashboard</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Current Subscription</h2>
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow">
          <p className="text-gray-800 dark:text-gray-200">Current Plan: {currentTier.charAt(0).toUpperCase() + currentTier.slice(1)}</p>
          <p className="text-gray-800 dark:text-gray-200">Tokens Remaining: {tokens_remaining}</p>
          {(currentTier === 'paid' || currentTier === 'premium') && (
            <button
              onClick={handleCancelSubscription}
              className="mt-4 bg-red-600 dark:bg-red-500 text-white px-4 py-2 rounded hover:bg-red-500 dark:hover:bg-red-400 transition duration-300"
              disabled={loading}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Available Plans</h2>
      <div className="grid md:grid-cols-2 gap-6">
        <SubscriptionCard
          tier="paid"
          title="Paid Plan"
          price="3"
          tokens={1000}
          features={[
            "Saved Asset Folders",
          ]}
          currentTier={currentTier}
          disabled={isPaidDisabled}
        />
        <SubscriptionCard
          tier="premium"
          title="Premium Plan"
          price="5"
          tokens={5000}
          features={[
            "Saved Asset Folders",
          ]}
          currentTier={currentTier}
          disabled={isPremiumDisabled}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
